<template>
  <main v-if="currentData" class="shueiJinJiouDetail">
    <section class="container shueiJinJiouDetail__container">
      <p class="shueiJinJiouDetail__billboard">{{ currentData.view }}</p>
      <h2 class="shueiJinJiouDetail__title">
        {{ currentData.title }}
      </h2>
      <hr class="shueiJinJiouDetail__hr" />
      <div class="shueiJinJiouDetail__content">
        <div
          :alt="`${currentData.title}`"
          class="shueiJinJiouDetail__img"
          :style="`background-image: url(${baseURL}${currentData.image})`"
        ></div>

        <p class="shueiJinJiouDetail__text">
          {{ currentData.describ }}
        </p>
      </div>
    </section>
    <GoBackFooter
      :routeItem="{
        text: `返回${routeItem.title}`,
        arrowString: '&#10229;',
        routeObj: { name: routeItem.routeName }
      }"
      :secondRouteItem="nextItem"
    />
  </main>
</template>
<script>
import GoBackFooter from '@/components/GoBackFooter.vue'

import ShueiJinJiouData from '@/assets/data/ShueiJinJiou'

// Api
import { apiGetShueiJinJui } from '@/api/webAPI'

export default {
  components: { GoBackFooter },
  data() {
    return {
      dataList: [],
      currentData: null
    }
  },
  computed: {
    routeItem() {
      const { title } = this.$route.query
      const findResult = ShueiJinJiouData.find(item => item.title === title)
      return findResult
    },
    nextItem() {
      const { title } = this.$route.query
      const { name } = this.$route
      const filterResult = this.dataList.filter(data => data.location === title)
      const currentIndex = filterResult.findIndex(
        data => data.sId === this.currentData.sId
      )

      if (currentIndex === filterResult.length - 1) return null

      return {
        text: '前往下一則故事',
        arrowString: '&#10230;',
        routeObj: {
          name,
          query: {
            ...this.$route.query,
            id: filterResult[currentIndex + 1].sId
          }
        }
      }
    },
    baseURL() {
      return process.env.VUE_APP_WEB_BASE
    }
  },
  watch: {
    $route(to, from) {
      this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      const { title, id } = this.$route.query
      if (!title || !id) {
        this.$router.push({ name: 'CultureWaterGold' })
        return
      }
      const response = (await apiGetShueiJinJui()).data.data
      this.dataList = response

      const currentData = response.find(data => data.sId === id)
      if (!currentData) {
        this.$router.push({ name: 'CultureWaterGold' })
        return
      }

      this.currentData = currentData
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="scss">
.shueiJinJiouDetail {
  background-color: #d3dfe0;

  &__container {
    background-color: white;
    padding: 7.7rem;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__billboard {
    background-color: #d5af05;
    display: inline-block;
    width: 5.5rem;
    height: 5.5rem;
    font-size: 1.4rem;
    line-height: 124%;
    font-weight: 500;
    padding: 1.1rem;
    text-align: center;
    position: relative;
    margin-right: 2.5rem;
  }

  &__billboard::after {
    /* http://apps.eky.hk/css-triangle-generator/zh-hant */
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11px 6.36px 0 6.36px;
    border-color: #d5af05 transparent transparent transparent;
    left: 50%;
    transform: translateX(-50%);
  }

  &__title {
    display: inline-block;
    font-weight: 500;
    letter-spacing: 0.2em;
    font-size: 2.7rem;
    line-height: 124%;
  }

  &__hr {
    width: 100%;
    border: none;
    border-bottom: 2px solid black;
    margin: 3.3rem auto;
  }

  &__content {
    display: flex;
  }

  &__content > * {
    flex: 1;
  }

  &__img {
    width: 100%;
    height: 100%;
    padding-bottom: 31.215%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 5rem;
  }

  &__text {
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 200%;
    text-align: justify;
  }
}

@media (max-width: 768px) {
  .shueiJinJiouDetail {
    &__container {
      padding: 2rem 2rem 4rem 2rem;
    }

    &__title {
      display: block;
      margin-top: 1.35rem;
    }

    &__hr {
      margin: 2.4rem auto;
    }

    &__content {
      flex-wrap: wrap;
    }

    &__content > * {
      flex: none;
    }

    &__img {
      padding-bottom: 62.43%;
      margin: 0;
      margin-bottom: 2rem;
    }

    &__text {
      width: 100%;
    }
  }
}
</style>

<template>
  <div class="exhibitFooter">
    <router-link
      @click.native.prevent.capture="onClickGoBack(routeItem)"
      :to="routeItem.routeObj"
      class="exhibitFooter__link clickable"
    >
      {{ routeItem.text }}
      <span style="font-size: 6rem" v-html="routeItem.arrowString"></span>
    </router-link>

    <router-link
      v-if="secondRouteItem"
      :to="secondRouteItem.routeObj"
      class="exhibitFooter__link exhibitFooter__link--second clickable"
    >
      {{ secondRouteItem.text }}
      <span style="font-size: 6rem" v-html="secondRouteItem.arrowString"></span>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    routeItem: {
      type: Object
    },
    secondRouteItem: {
      type: Object,
      default: null
    }
  },
  methods: {
    async onClickGoBack(routeItem) {
      if (!routeItem.useHistory) {
        this.$router.push(routeItem.routeObj)
        return
      }
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss">
.exhibitFooter {
  border-top: 10px solid #d0a805;
  background-image: url('~@/assets/image/Exhibit/ExhibitFooter.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &__link {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.6rem;
    line-height: 142.5%;
    font-weight: 500;
    padding: 0 5rem;
  }
}

@media (max-width: 768px) {
  .exhibitFooter {
    flex-wrap: wrap;

    &__link {
      width: 100%;
      margin: 3.4rem auto;
    }

    &__link--second {
      margin-left: 0;
    }
  }
}
</style>

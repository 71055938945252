export default [
  {
    title: '水湳洞',
    bgColor: '#D4E2C9',
    image: require('@/assets/image/Culture/WaterGold/水湳洞.png'),
    bgImage: require('@/assets/image/ShueiJinJiou/ShueiNanDongBanner.png'),
    routeName: 'ShueiJinJiouShueiNanDong'
  },
  {
    title: '金瓜石',
    bgColor: '#D0E0E0',
    image: require('@/assets/image/Culture/WaterGold/金瓜石.png'),
    bgImage: require('@/assets/image/ShueiJinJiou/JinGuaShihBanner.png'),
    routeName: 'ShueiJinJiouJinGuaShih'
  },
  {
    title: '九份',
    bgColor: '#E5E5DF',
    image: require('@/assets/image/Culture/WaterGold/九份.png'),
    bgImage: require('@/assets/image/ShueiJinJiou/JiouFenBanner.png'),
    routeName: 'ShueiJinJiouJiouFen'
  }
]
